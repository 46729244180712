import { debug } from "console";
import {
  IDistLatLongInZip,
  ILocation,
  Ilatlong,
  ILocationGridProps,
} from "../types/global.typing";
import distance from "../core/utilities/dataUtilities";

const GeoSearchList = ({
  locations,
  uniqueZipLatLong,
  searchText,
  searchDistance,
  latitude,
  longitude,
}: ILocationGridProps) => {
  if (
    searchText.length < 5 &&
    (latitude === undefined || longitude === undefined)
  ) {
    return (
      <main>
        <article>
          <h2>Emissions Testing Requirements</h2>
          <p>
            In 1982 Virginia passed legislation to implement an air quality
            program similar to California. While all vehicles in Virginia are
            required to be safety inspected, now every 2 years if you live in
            specific cities or counties you are also required to have your
            vehicles' emissions tested. If you live in Alexandria, Fairfax,
            Loudoun, Prince Williams, and Stafford counties, or in the cities of
            Alexandria, Fairfax, Falls Church, Manassas and Manassas Park you
            will need to emissions test your vehicle at some time.
          </p>
          <p>
            Emissions inspection in Virginia is performed by the Virginia DMV in
            cooperation with the Virginia Department of Environmental Quality
            (DEQ) if you live in qualifying counties or cities and own a
            gasoline or diesel vehicle. Vehicle Safety inspection in Virginia is
            statewide, required for all vehicles and administered by the State
            Police. Vehicle smog checks are required in these cases:
          </p>
          <ul>
            <li>
              Qualifying gasoline or diesel vehicle registered for the first
              time in Virginia unless the previouosly registered state has
              reciprocal testing standards.
            </li>
            <li>
              If you are registering a vehicle previously registered in Virginia
              and it does not have a valid emission inspection certificate.
            </li>
            <li>
              Every two years on DMV registration renewal if your vehicle is
              gasoline powered less than 25 years old, and less than 10,000
              lbs., or is diesel powered made in 1997 or newer and its gross
              vehicle weight is more than 8,500 lbs.
            </li>
          </ul>
          <div>
            Some vehicles are exempt from emissions testing.
            <ul>
              <li>Newly purchased vehicles titled for the first time</li>
              <li>
                Gasoline powered vehicles older than 25 years regardless if they
                are registered as antique
              </li>
              <li>
                Gasoline vehicle with a gross vehicle weight of more than 10,000
                lbs.
              </li>
              <li>
                Diesel powered vehicles made before 1997 and with a gross
                vehicle weight of more than 8,000lbs.
              </li>
              <li>
                Clean fuels vehicles: Compressed Natural Gas (CNG), Liquified
                Natural Gas (LNG), Electric and Solar.
              </li>
              <li>Some hybrids are exempt</li>
              <li>Motorcycles</li>
            </ul>
          </div>
        </article>
        <article>
          <h2>Safety Inspection Requirements</h2>
          <p>
            Safety inspections are required of all vehicles and trailers that
            have brakes. The Virginia State Police administer the vehicle safety
            inspection program. All qualifying vehicles statewide must pass a
            safety inspection yearly. Many repair shops and most automobile
            dealers can conduct a safety inspection.
          </p>
          <p>The fees charged for the safety inspections are regulated. </p>
          <ul>
            <li>
              $51 for each inspection of any tractor truck or truck with a gross
              vehicle weight rating of 26,000lbs or a vehicle used to transport
              more thant 15 passengers including driver.
            </li>
            <li>$12 for each inspection of any motorcycle or autocycle.</li>
            <li>
              $20 for each inspection of any other type of vehicle, including
              trailers and motor homes.
            </li>
          </ul>
          <p>The general inspection checklist is:</p>
          <ul>
            <li>Inspect Brakes</li>
            <li>Inspect Parking Brake</li>
            <li>Inspect Headlights</li>
            <li>Inspect Other Lights</li>
            <li>Inspect Signaling and Brake Lightsd</li>
            <li>Inspect Steering and Suspension</li>
            <li>Inspect Tires, Wheels, &amp; Rims</li>
            <li>Inspect Mirrors</li>
            <li>Inspect Horn</li>
            <li>Inspect Windshield and other Glass</li>
            <li>Inspect Exhaust System</li>
            <li>Inspect Engine Compartment</li>
            <li>Inspect Air Pollusion Control Systems</li>
            <li>Inspect Drivers Seat and Seat Belts</li>
            <li>Inspect Doors</li>
            <li>Inspect Fuel System</li>
            <li>Inspect Floor and Frame</li>
          </ul>
        </article>
      </main>
    );
  }

  // get random lat, long from zipcodes, do set thing filtering on zip and pass that to the function getRandomLocationFromZip
  let latLongValue = Array<IDistLatLongInZip>();
  if (searchText.length === 5) {
    latLongValue = uniqueZipLatLong.filter((x) => x.zip === searchText);
  }
  let distResults = locations.filter((location, i) => {
    location.distance = distance(
      searchText.length === 5 ? latLongValue[0].latLong.lat : latitude ?? -1, //39.1145502, //
      searchText.length === 5 ? latLongValue[0].latLong.long : longitude ?? -1, //-77.5813749,
      location.latitude,
      location.longitude
    );

    return location.distance <= searchDistance;
  });

  return (
    <ol className="list-group gradient-list ">
      {/* <div className="col-8">
        <div>Locations</div>
      </div> */}
      {distResults
        .sort((a, b) => a.distance - b.distance)
        .map((location, i) => {
          return (
            <li key={i} className="list-group-item ">
              <div
                className="row"
                itemScope
                itemType="https://schema.org/PostalAddress"
              >
                <h3
                  className="border border-2 rounded-5 text-center p-3"
                  itemProp="name"
                  itemScope
                  itemType="https://schema.org/LocalBusiness"
                >
                  {location.name}
                </h3>
                <div itemProp="address" className="adr">
                  <div itemProp="streetAddress" className="street-address col">
                    {location.street}
                  </div>
                  <span itemProp="addressLocality" className="locality col">
                    {location.city}
                    {", "}
                  </span>
                  <span itemProp="addressRegion" className="region col">
                    VA{"  "}
                  </span>
                  <span itemProp="postalCode" className="postal-code col">
                    {location.zip}
                  </span>
                  <div itemProp="telephone">
                    {" "}
                    <a href={"tel:" + location.phone} className="tel col">
                      {location.phone}
                    </a>
                  </div>
                </div>
                <div className="col distance">
                  {location.distance.toFixed(2)}
                  {" miles away"}
                </div>
              </div>
            </li>
          );
        })}
    </ol>
  );
};

export default GeoSearchList;
