import React, { useState, lazy, Suspense, useEffect } from "react";
import { ReactDOM } from "react";
import { useGeolocated } from "react-geolocated";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import GeoSearchList from "./components/geoSearchList.component";
import SearchBox from "./components/searchBox.component";
import BlogList from "./components/blogListcomponent";
import { IBlogData, IDistLatLongInZip, ILocation } from "./types/global.typing";
import { generateUniqueZipLatLong } from "./core/utilities/dataUtilities";

//import { blogData } from "./data/blog";

import "./App.css";

const App = () => {
  const [searchText, setSearchText] = useState("");
  const [distanceValue, setdistanceValue] = useState(10);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [blogposts, setBlogPosts] = useState<IBlogData[]>([]);
  const [data, setData] = useState<string[]>([]);
  const [distLatLongInZip, setDistLatLongInZip] = useState<IDistLatLongInZip[]>(
    []
  );

  const logo = require("./images/small-check-smog-logo.png");
  const handleChange = (e: string) => {
    setSearchText(e);
  };

  const handleDistanceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setdistanceValue(parseInt(e.target.value));
  };

  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  let LOCATIONS: ILocation[];
  let blogData: IBlogData[];
  useEffect(() => {
    // create list of zip codes and long, lat and list of distinct zip codes.  pass to Searchbox component
    // call web service but for now just use the existing array.
    fetch("../data/locations.json")
      .then((resp) => resp.text())
      .then((data) => {
        LOCATIONS = JSON.parse(data) as ILocation[];
        setLocations(LOCATIONS);
        setData([...new Set(LOCATIONS.map((i) => i.zip))]);
        setDistLatLongInZip(
          generateUniqueZipLatLong(
            [...new Set(LOCATIONS.map((i) => i.zip))],
            LOCATIONS
          )
        );
      });
    fetch("../data/posts.json")
      .then((resp) => resp.text())
      .then((data) => {
        console.log(JSON.parse(data) as IBlogData);
        blogData = JSON.parse(data) as IBlogData[];
        setBlogPosts(blogData);
      });
  }, []);

  if (locations.length === 0 || data.length === 0)
    return (
      <p>loading Virginia Emissions &amp; Safety Inspection Locations...</p>
    );

  return (
    <>
      <h1 className="App-header text-center">
        <img src={logo} className="img-fluid float-start App-logo" />
        Virginia Emissions Test &amp; Safety Inspection Locations
      </h1>
      <div className=" p-1">
        <Row>
          <SearchBox
            value={searchText}
            zips={data}
            distanceValue={distanceValue}
            handleChange={handleChange}
            handleDistanceChange={handleDistanceChange}
          />
        </Row>
        <Row className="resultlist m-1">
          <Col className="col-xs-12 col-sm-12 col-md-7 border blogrow rounded">
            <GeoSearchList
              locations={locations}
              uniqueZipLatLong={distLatLongInZip}
              searchText={searchText}
              searchDistance={distanceValue}
              latitude={
                isGeolocationAvailable &&
                isGeolocationEnabled &&
                searchText.length === 0
                  ? coords?.latitude
                  : undefined
              }
              longitude={
                isGeolocationAvailable &&
                isGeolocationEnabled &&
                searchText.length === 0
                  ? coords?.longitude
                  : undefined
              }
            />
          </Col>
          <Col className="col-5 border boarder-secondary rounded d-none d-md-block">
            <BlogList blogposts={blogposts} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default App;
